/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var linkerParam;
analytics.ready(function() {
  ga(function(tracker) {
    linkerParam = (tracker.get('linkerParam') || '').split('=').pop();
  });
});

(function($) {

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args); // jshint ignore:line
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args); // jshint ignore:line
    };
  }

  function buildQuery(params, exclude) {
    var parts = [];
    exclude = exclude || [];
    for (var key in params) {
      if(params[key] && exclude.indexOf(key) === -1) {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
    }
    return parts.join("&");
  }

  function prefixKeys(prefix, obj) {
    var keys = Object.keys(obj);
    var result = {};

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      result[prefix + key] = obj[key];
    }

    return result;
  }

function getPublicIP() {
  return fetch("https://api.ipify.org?format=json")
    .then(function (response) {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(function (data) {
      return data.ip || ''; 
    })
    .catch(function (error) {
      console.error("Error fetching public IP:", error);
      return '';
    });
}

  var validEmailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var validPasswordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.*([A-Za-z0-9])\1{2}).{12,}$/; 

  // --SHOW PASSWORD REQUIREMENTS ARE BEING MET AS USER TYPES--

  // timeout before a callback is called
  var timeout;
  // traversing the DOM and getting the input and requirement lis using their IDs
  var passwordInput = document.getElementById('password');
  var reqUpper = document.getElementById('req-one-upper');
  var reqLower = document.getElementById('req-one-lower');
  var reqNum = document.getElementById('req-one-num'); 
  var reqSpec = document.getElementById('req-one-special');
  var reqMin = document.getElementById('req-twelve-min');
  var reqTriple = document.getElementById('req-no-triplicates');
  // regex patterns to check
  var validPasswordRegexNum = new RegExp('(?=.*[0-9])');
  var validPasswordRegexSpecial = new RegExp('(?=.*[!@#$%^&*])');
  var validPasswordRegexLower = new RegExp('(?=.*[a-z])');
  var validPasswordRegexUpper = new RegExp('(?=.*[A-Z])(?=.*[a-zA-Z])');
  var validPasswordRegexMin = new RegExp('(?=.{12,})');
  var validPasswordRegexTriple = /^(?=.*[a-zA-Z])(?!.*([A-Za-z0-9])\1{2}).*$/;
  // if requirement is met then turn corresponding li text in the pw-info-box green
  function StrengthChecker(PasswordParameter){
    console.log(validPasswordRegex.test(PasswordParameter));
      if(validPasswordRegexNum.test(PasswordParameter)) {
          reqNum.style.color = "green";
      } 
      if(validPasswordRegexUpper.test(PasswordParameter)) {
          reqUpper.style.color = "green";
      }
      if(validPasswordRegexLower.test(PasswordParameter)) {
          reqLower.style.color = "green";
      }
      if(validPasswordRegexSpecial.test(PasswordParameter)) {
          reqSpec.style.color = "green";
      }
      if(validPasswordRegexMin.test(PasswordParameter)) {
          reqMin.style.color = "green";
      }
      if(validPasswordRegexTriple.test(PasswordParameter)) {
          reqTriple.style.color = "green";
      }
      // if all requirements are met then enable submit button
      if(validPasswordRegex.test(PasswordParameter)) {
        document.getElementById('pw-submit-btn').removeAttribute("disabled");
      } 
  }

  //Adding an input event listener when a user types to the password input 
  if(passwordInput){
    passwordInput.addEventListener("input", function (e) {
      //The req li is black by default
      $('.req-li').css("color", "black");
      clearTimeout(timeout);
      //call StrengChecker function as a callback then pass the typed password to it
      timeout = setTimeout(function (e){
        StrengthChecker((passwordInput.value),500);
        // console.log(passwordInput.value);
      });
      //Incase a user clears the text, the req li will go back to black again and submit button is disabled
      if(passwordInput.value.length !== 0){
        $('.req-li').css("color", "black");
        document.getElementById('pw-submit-btn').setAttribute("disabled","disabled");
      }
    });

  }

  // --MAIN VALIDATE PASSWORD FUNCTION--
  var validateField = function(object) {
    var isValid = true;
    var lenAttr = parseInt(object.attr('data-length'));
    var len = object[0].value.trim().length; 
    var value = object[0].value;
    var type = object[0].type;

    if(value) {
      if('email' === type) {
        if(!validEmailRegex.test(value)) {
          isValid = false;
        }
      }
      else if('password' === type) {
        if(!validPasswordRegex.test(value)) {
          isValid = false;
        }
      }

      if(lenAttr && len < lenAttr) {
        isValid = false;
      }
    }
    else {
      isValid = false;
    }

    if(isValid) {
      object.addClass('valid');
      object.removeClass('invalid');
    }
    else {
      object.addClass('invalid');
      object.removeClass('valid');
    }
    return isValid;
  };    


  // acknowledgements on sign-up forms
  var checkBoxes = $('input.acknowledge'),
    submitButton = $('#sign-up-btn');

  checkBoxes.change(function () {
      submitButton.attr("disabled", checkBoxes.is(":not(:checked)"));
      if(checkBoxes.is(":not(:checked)")) {
          submitButton.attr('disabled');
      } else {
          submitButton.attr('enabled');
      }       
  });


  function horizontalScroller(el, opts) {
    opts = opts || {};

    var $wrapper = $(el);

    if(!$wrapper.length) {
      return;
    }

    var $scroller = $wrapper.find('.scroll');
    var $cells = $wrapper.find('.horizontal-scroller__cell');
    var $scrollbar = $wrapper.find('.hiding-scrollbar');
    var $indicators = $wrapper.find('.indicators');
    var isClicked = false;
    var indicatorsNumber = $cells.length;

    var updateIndicators = function(index) {
      $indicators.children().removeClass('active');
      $indicators.children().eq(index).addClass('active');
    };

    var handleOnScroll = debounce(function() {
      if(isClicked) {
        return false;
      }

      var itemWidth = $scroller[0].scrollWidth / indicatorsNumber;
      var index = 0;

      $cells.each(function(idx) {
        if($scroller[0].scrollLeft > itemWidth * idx) {
          index++;
        }
      });

      updateIndicators(index);
    }, 50);

    var buildIndicators = function() {
      var number = Math.ceil($cells.length / parseInt($scroller.outerWidth() / $cells.outerWidth()));

      if(number !== indicatorsNumber) {
        var li = '';
        for(var i = 0; i < number; i++) {
          li += '<li></li>';
        }
        $indicators.html(li);
        indicatorsNumber = number;
      }

      handleOnScroll();
    };

    var handleOnResize = debounce(function() {
      buildIndicators();

      if($scroller[0].scrollWidth <= $scroller.outerWidth()) {
        $indicators.hide();
        $scrollbar.hide();
      }
      else {
        $indicators.show();
        $scrollbar.show();
      }
    }, 250);

    $indicators.on('click', 'li', function() {
      isClicked = true;

      var index = $(this).index();
      var scrollLeft = $scroller[0].scrollWidth / indicatorsNumber;

      $scroller.animate({
        scrollLeft: scrollLeft * index
      }, function() {
        isClicked = false;
      });

      updateIndicators(index);
    });

    $(window).on('resize', handleOnResize);
    handleOnResize();
    $scroller.on('scroll', handleOnScroll);
  }

  var $siteBanner = $('.site-banner');

  if ($siteBanner.length) {
    var accomodateForSiteBannerHeight = debounce(function() {
      var height = $siteBanner.outerHeight();

      document.body.style.paddingTop = height + 'px';
    }, 250);
  }

  // POST to WP REST API
  // FOR SIGNUP
  // function call_signup(data){
  //   var postUrl = '/wp-json/api/v1/post_signup';
  //   fetch(postUrl, {
  //     method: 'POST',
  //     body: JSON.stringify(data)
  //   })
  //   .then(response => {
  //     return response.json();
  //   })
  //   .then(jsonResponse => {
  //     // Hide form and show alert
  //     // $('#form-wrapper').hide();
  //     // $('#alert-wrapper').show();

  //   })
  //   .catch(error =>{
  //     console.log( error );
  //   });
  // }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'page_template_page_landing_page': {
      init: function() {
        Sage.page_template_page_signup.init();
      }
    },
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function updateSigninLink(){
          var url = window.location.hostname;
          if (url === 'pr.mailcharts.com') {
            $('.signin-link-wrap a').each(function() {
                $(this).attr('href', 'https://pr-app.tst.mailcharts.com/signin');
            });
          } else if (url === 'staging.mailcharts.com') {
            $('.signin-link-wrap a').each(function() {
                $(this).attr('href', 'https://staging-app.tst.mailcharts.com/signin');
            });
          } else {
            $('.signin-link-wrap a').each(function() {
                $(this).attr('href', 'https://app.mailcharts.com/signin');
            });
          }
        }
        updateSigninLink();

        var siteBanner = document.querySelector('.site-banner');
        if ( siteBanner ) {
          accomodateForSiteBannerHeight();
          window.addEventListener('resize', accomodateForSiteBannerHeight);
        }

        // Detect UTM parameters and store in a JS cookie:
        var EXPIRY_DAYS = 30;
        var now = new Date();
        now.setTime(now.getTime() + (EXPIRY_DAYS * 24 * 60 * 60 * 1000));
        var expires = 'expires='+ now.toUTCString();
        var str = window.location.href.split('?')[1] || '';
        (str.split('&') || []).forEach(function(kv) {
          var key = decodeURIComponent(kv.split('=')[0]);
          var value = decodeURIComponent(kv.split('=')[1]);
          // only set cookie if UTM parameter:
          if (value && /^utm_/i.test(key)) {
            document.cookie = key + '=' + value + ';' + expires + ';path=/';
          }
        });

        ////////////////////////////////////////////////
        // Detect if email iframe resize is necessary //
        ////////////////////////////////////////////////
        function handleEvent(e) {
          var iframeHeight = e.detail.height;
          var iframeWidth = e.detail.width;
          $('iframe').height( iframeHeight - 4 );
          $('.email-preview').height( iframeHeight - 4 );
          $('iframe').width( '100%' );
          $('.email-preview').width( iframeWidth - 4 );
        }
        window.document.addEventListener('emailIframeResize', handleEvent, false);

        ///////////////////////////////////
        // Smooth scroll on anchor links //
        ///////////////////////////////////
        $('a[href^="#"]').on('click', function(event){
          event.preventDefault();
          var target = $(this.hash);
          if( ! $(this).data('toggle') && $(target).length){
            // is not an accordion toggle
            $('html,body').animate({scrollTop:$( target ).offset().top}, 500);
          }
        });

        ////////////////////////
        // Fluid video sizing //
        ////////////////////////
        $(function() {
          var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], iframe[src^='https://www.youtube.com'], object, embed"),
          $fluidEl = $("body");

          $allVideos.each(function() {
            $(this)
              // jQuery .data does not work on object/embed elements
              .attr('data-aspectRatio', this.height / this.width)
              .removeAttr('height')
              .removeAttr('width');
          });

          $(window).resize(function() {
            $allVideos.each(function() {
              var $el = $(this);
              var newWidth = $el.parent().width();
              $el.parent().css('text-align', 'center');
              $el
                  .width(newWidth)
                  .height(newWidth * $el.attr('data-aspectRatio'));
            });
          }).resize();

        });

        /////////////////////////////////////
        // Remove line under image in link //
        /////////////////////////////////////
        $('a > img').parent().css({'text-decoration' : 'none'});

        ////////////////////////////
        // Features Dropdown Menu //
        ////////////////////////////

        var $dropdownOverlay = $('<div id="dropdownOverlay"></div>');
        $(document.body).append($dropdownOverlay);

        $('.nav-primary .nav > li.menu-item-has-children').on('mouseenter', function() {
          $dropdownOverlay.addClass('is-open-overlay');
        });

        $('.nav-primary .nav > li.menu-item-has-children').on('mouseleave', function() {
          $dropdownOverlay.removeClass('is-open-overlay');
        });

        // mobile menu level
        var MenuLevel = {
          $menus: $('.menu-level, .menu-level-back'),
          isBackNavigation: 1,
          init: function() {
            var self = this;
            this.$menus.on('webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend', function(e) {
              self.$target.addClass('menu-level--current');

              self.$current.removeClass(!self.isBackNavigation ? 'animate-outToLeft' : 'animate-outToRight');
              self.$target.removeClass(!self.isBackNavigation ? 'animate-inFromRight' : 'animate-inFromLeft');
            });

            this.$menus.on('click', 'a', function(e) {
              var $trigger = $(this);

              if($trigger.data('sub-menu')) {
                e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line

                self.isBackNavigation = 0;

                self.$current = $trigger.closest('.menu-level');
                self.$target = $('[data-menu="' + $trigger.data('sub-menu') + '"]');

                self.$current.addClass('animate-outToLeft');
                self.$target.addClass('animate-inFromRight');

                self.$current.removeClass('menu-level--current');
              }
              else if($trigger.data('back')) {
                e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line

                self.isBackNavigation = 1;

                self.$current = $trigger.closest('.menu-level');
                self.$target = $('[data-menu="' + $trigger.data('back') + '"]');

                self.$current.addClass('animate-outToRight');
                self.$target.addClass('animate-inFromLeft');

                self.$current.removeClass('menu-level--current');
              }
            });

            // allow overflow content to scroll
            $('#navbarCollapse').on('shown.bs.collapse', function() {
              self.$menus.each(function() {
                if('menu-level-back' !== this.className) {
                  if($(this).outerHeight() > $(window).height()) {
                    var $content = $(this).find('.sub-menu-content');
                    if(!$content.hasClass('scrollable')) {
                      $content.addClass('scrollable');
                    }
                  }
                }
              });
            });
          }
        };

        MenuLevel.init();

      }
    },
    // Home page
    'home': {
      init: function() {

        // our-data custom slider
        $('.horizontal-scroller').each(function() {
          horizontalScroller(this, { cells: '.horizontal-scroller__cell' });
        });

      }
    },
    // Companies
    'companies': {
      init: function() {

        // similar companies
        $('.horizontal-scroller').each(function() {
          horizontalScroller(this, { cells: '.horizontal-scroller__cell' });
        });

        var $collapsedElements = $('.companies-list-container');
        var $lastOpenCollapsedElement = $collapsedElements.first();
        var $dropdownElements = $('.companies-nav a');
        var $lastOpenDropdownElement = $dropdownElements.first();
        var $dropdownTriggerButton = $('#companiesMenuButton');

        $dropdownElements.on('click', function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line

          $lastOpenDropdownElement.attr('aria-expanded', false);
          $lastOpenCollapsedElement.hide();

          $lastOpenDropdownElement = $(this);
          $lastOpenDropdownElement.attr('aria-expanded', true);
          $lastOpenCollapsedElement = $($lastOpenDropdownElement.data('href'));
          $lastOpenCollapsedElement.show();

          $dropdownTriggerButton.text($lastOpenDropdownElement.text());
        });

        $('.companies-list-more button').on('click', function() {
          var $el = $(this);
          var $list = $el.closest('.companies-list-more-wrapper');

          if($list.hasClass('is-open')) {
            $list.removeClass('is-open');
            $el.text('Show more');
          }
          else {
            $list.addClass('is-open');
            $el.text('Hide');
          }
        });

        $( "form.cta-signup-form" ).submit( function( event ) {
          var $form = $(this);
          // get vars
          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var env = $form.data('env');
          var api_url = $form.data('api-url');
          var company_id = $form.data('company_id');
          var slug = $form.data('name');
          var type = $form.data('type');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              company_id: company_id,
              slug: slug,
              type: type,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              window.analytics.track('Confirmation email sent', {
                company_id: company_id,
                slug: slug,
                type: type,
                url: window.location.href
              });
              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index,html){
                html = html.replace('/signup', '/signup?company_id=' + company_id + '&slug=' + slug.replace(/ /g, '+').replace(/'/g, '%27') + '&type=' + type);
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
          event.preventDefault();
        });

      }
    },
    // Blog Post
    'single_post': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var $wrap = $form.closest('.blog-signup-form');
          var $error = $form.find('.error');
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              $button.attr('disabled', false);
              window.analytics.track('Confirmation email sent', {
                url: window.location.href
              });

              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index, html){
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              $button.attr('disabled', false);
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
        });

      },
      finalize: function() {
      }
    },
    // Blog
    'blog': {
      init: function() {

        $( "form.blog-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var api_url = '/wp-json/api/v1/signup';

          var $wrap = $form.closest('.card-sign-up');
          var $error = $form.find('.error');
          // var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          var data = {
            email: email,
            _ga: _ga,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_term: utm_term
          };

          // Call MC API
          function callMCAPI() {
            $.ajax({
              url: api_url,
              xhrFields: {
                withCredentials: true
              },
              data : data,
              method: method,
              success: function(result) {
                switch (result) {
                  case 500:
                    var errorMessage = 'Email not valid';
                    $('.error-msg').text(errorMessage);
                    $('.error').show();
                  break;
                  case 409:
                    $('.error-msg').text('Email already exists. Sign in!');
                    $('.error').show();
                  break;
                  default:
                    $button.attr('disabled', false);
                    // console.log('MCAPI');
                    // console.log(result);
                    var props = prefixKeys('sign_up_', params);
                    window.analytics.track('Confirmation email sent', props);

                    if(redirect) {
                      params.redirect = redirect;
                    }

                    $('.signup-step-1').hide();
                    $('.signup-step-2').html(function(index,html){
                      var query = buildQuery(params, ['emails', 'company_name', 'list_name', 'group_name']);
                      if(query) {
                        html = html.replace('/signup', '/signup?' + query);
                      }
                      return html.replace('[email]', email);
                    });
                    $('.signup-step-2').show();
                    }                
              },
              error: function(jqXHR, textStatus, errorThrown){
                console.log('Error');
                $button.attr('disabled', false);
                switch (jqXHR.status) {
                  case 500: 
                  var errorMessage = 'Email not valid';
                  $('.error-msg').text(errorMessage);
                  $('.error').show();
                  break;
                  case 409: 
                    $('.error-msg').text('Email already exists. Sign in!');
                    $('.error').show();
                    break;
                  default:
                    jsonResponse = JSON.parse(jqXHR.responseText);
                    if(jsonResponse.message!==undefined){
                      errorMessage = jsonResponse.message;
                      $('.error-msg').text(errorMessage);
                      $('.error').show();
                    }
                }
              }
            });
          }
          // reCaptcha
          var token = $('#g-recaptcha-response').val();
          var recaptch_data = {
            token: token,
          };
          $.ajax({
            url: "/wp-json/api/v1/recaptcha",
            xhrFields: {
              withCredentials: true
            },
            data : recaptch_data,
            method: 'POST',
            success: function(result) {
              console.log('recaptcha3');
              console.log(result.success);
              
              // Call internal MC API
              callMCAPI();

            },
            error: function(jqXHR, textStatus, errorThrown){
              console.log('Error, failed reCaptcha');
              $('.error-msg').text('Error, failed reCaptcha');
              $('.error').show();
            }
          });
          
        });

      }
    },
    // Blog Category
    'category': {
      init: function() {

        $( "form.blog-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var $wrap = $form.closest('.card-sign-up');
          var $error = $form.find('.error');
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              $button.attr('disabled', false);
              window.analytics.track('Confirmation email sent', {
                url: window.location.href
              });

              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index, html){
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              $button.attr('disabled', false);
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
        });

      }
    },
    // Email examples
    'single_email_examples': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function( event ) {
          var $form = $(this);
          // get vars
          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var env = $form.data('env');
          var api_url = $form.data('api-url');
          var list_id = $form.data('list_id');
          var slug = $form.data('slug');
          var type = $form.data('type');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              list_id: list_id,
              slug: slug,
              type: type,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              window.analytics.track('Confirmation email sent', {
                list_id: list_id,
                slug: slug,
                type: type,
                url: window.location.href
              });
              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index,html){
                html = html.replace('/signup', '/signup?list_id=' + list_id + '&slug=' + slug + '&type=' + type);
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
          event.preventDefault();
        });

      }
    },
    // SMS examples
    'single_sms_examples': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function( event ) {
          var $form = $(this);
          // get vars
          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var env = $form.data('env');
          var api_url = $form.data('api-url');
          var list_id = $form.data('list_id');
          var slug = $form.data('slug');
          var type = $form.data('type');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              list_id: list_id,
              slug: slug,
              type: type,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              window.analytics.track('Confirmation email sent', {
                list_id: list_id,
                slug: slug,
                type: type,
                url: window.location.href
              });
              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index,html){
                html = html.replace('/signup', '/signup?list_id=' + list_id + '&slug=' + slug + '&type=' + type);
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
          event.preventDefault();
        });

      }
    },
    // Industry insights
    'single_industry_insights': {
      init: function() {

        // similar companies custom slider
        $('.horizontal-scroller').each(function() {
          horizontalScroller(this, { cells: '.horizontal-scroller__cell' });
        });

        $( "form.cta-signup-form" ).submit( function( event ) {
          var $form = $(this);
          // get vars
          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var env = $form.data('env');
          var api_url = $form.data('api-url');
          var list_id = $form.data('list_id');
          var slug = $form.data('slug');
          var type = $form.data('type');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              list_id: list_id,
              slug: slug,
              type: type,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              window.analytics.track('Confirmation email sent', {
                list_id: list_id,
                slug: slug,
                type: type,
                url: window.location.href
              });
              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index,html){
                html = html.replace('/signup', '/signup?list_id=' + list_id + '&slug=' + slug + '&type=' + type);
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
          event.preventDefault();
        });

      }
    },
    // Holiday Page
    'single_holiday': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function( event ) {
          var $form = $(this);
          // get vars
          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var env = $form.data('env');
          var api_url = $form.data('api-url');
          var list_id = $form.data('list_id');
          var slug = $form.data('slug');
          var type = $form.data('type');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              list_id: list_id,
              slug: slug,
              type: type,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              window.analytics.track('Confirmation email sent', {
                list_id: list_id,
                slug: slug,
                type: type,
                url: window.location.href
              });
              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index,html){
                html = html.replace('/signup', '/signup?list_id=' + list_id + '&slug=' + slug + '&type=' + type);
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
          event.preventDefault();
        });
      },
      finalize: function() {
      }
    },
    'page_template_page_features': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              $button.attr('disabled', false);
              window.analytics.track('Confirmation email sent', {
                url: window.location.href
              });

              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index, html){
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              $button.attr('disabled', false);
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
        });
      }
    },
    'page_template_page_dynamic': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              $button.attr('disabled', false);
              window.analytics.track('Confirmation email sent', {
                url: window.location.href
              });

              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index, html){
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              $button.attr('disabled', false);
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
        });
      }
    },
    // Login page
    'page_template_page_login': {
      init: function() {
        var u = new URL(window.location.href);
        var redirect = u.searchParams.get('redirect');

        if(redirect){
          history.pushState(null, '', u.toString());
        }

        $( "form.login" ).submit(function( event ) {
          // get vars
          var env = $(this).data('env');
          var api_url = $(this).data('api-url');
          var method = 'POST';
          var success_url = redirect || $(this).data('success-url');
          var email = $(this).find('input[name=email]').val();
          var password = $(this).find('input[name=password]').val();
          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              password: password,
            },
            method: method,
            success: function(result) {
              var identify = $.Deferred();
              var user = {};
              try { user = JSON.parse(result); } catch(err) {/* do nothing*/ }
              var id = user.id;
              var options = { email: user.email };
              window.analytics.identify(id, options, identify.resolve);
              setTimeout(function() { identify.resolve(); }, 250);
              identify.promise().then(function() {
                window.location.href = success_url;
              });
            },
            error: function(jqXHR, textStatus, errorThrown){
              var jsonResponse = JSON.parse(jqXHR.responseText);
              var errorMessage = jsonResponse.message;
              $('.error-msg').text(errorMessage);
              $('.error').show();
            }
          });

          event.preventDefault();
        });
      },
      finalize: function() {
      }
    },
    // Signup page
    'page_template_page_signup': {
      init: function() {

        var u = new URL(window.location.href);
        var redirect = u.searchParams.get('redirect');

        var input_selector = 'input[type=email]';
        $(document).on('change', input_selector, function() {
          validateField($(this));
        });

        $( "form.signup-form" ).submit( function( e ) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);
          var isValid = validateField($form.find('input[type="email"]'));

          if(!isValid) {
            return;
          }

          // console.log('Start');

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          // get vars
          var env = $form.data('env');
          // var api_url = $form.data('api-url');
          var api_url = '/wp-json/api/v1/signup';

          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          var params = $form.data('params') || {};
          var terms = $form.find('input[name=acknowledge]').val();
          var privacy = $form.find('input[name=acknowledge_policies]').val();

          getPublicIP().then(function (ip) {
            var data = {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term,
              terms_of_service_version: terms,
              privacy_policy_version: privacy,
              address: ip ? ip : '',
            };

            if(redirect) {
              data.redirect = redirect;
            }

            if(params) {
              $.extend(data, params);
            }

            delete data.company_name;

            // Call MC API
            function callMCAPI() {
              $.ajax({
                url: api_url,
                xhrFields: {
                  withCredentials: true
                },
                data : data,
                method: method,
                success: function(result) {
                  console.log(result);
                  switch (result) {
                    case 500:
                      var errorMessage = 'Email not valid';
                      $('.error-msg').text(errorMessage);
                      $('.error').show();
                    break;
                    case 409:
                      $('.error-msg').text('Email already exists. Sign in!');
                      $('.error').show();
                    break;
                    default:
                      $button.attr('disabled', false);
                      // console.log('MCAPI');
                      // console.log(result);
                      var props = prefixKeys('sign_up_', params);
                      window.analytics.track('Confirmation email sent', props);

                      if(redirect) {
                        params.redirect = redirect;
                      }

                      $('.signup-step-1').hide();
                      $('.signup-step-2').html(function(index,html){
                        var query = buildQuery(params, ['emails', 'company_name', 'list_name', 'group_name']);
                        if(query) {
                          html = html.replace('/signup', '/signup?' + query);
                        }
                        return html.replace('[email]', email);
                      });
                      $('.signup-step-2').show();
                      }                
                },
                error: function(jqXHR, textStatus, errorThrown){
                  console.log('Error');
                  $button.attr('disabled', false);
                  switch (jqXHR.status) {
                    case 500: 
                    var errorMessage = 'Email not valid';
                    $('.error-msg').text(errorMessage);
                    $('.error').show();
                    break;
                    case 409: 
                      $('.error-msg').text('Email already exists. Sign in!');
                      $('.error').show();
                      break;
                    default:
                      
                      jsonResponse = JSON.parse(jqXHR.responseText);
                      console.log(jqXHR.responseText);
                      if(jsonResponse.message!==undefined){
                        errorMessage = jsonResponse.message;
                        $('.error-msg').text(errorMessage);
                        $('.error').show();
                      }
                  }
                }
              });
            }

            // reCaptcha
            var token = $('#g-recaptcha-response').val();
            var recaptch_data = {
              token: token,
            };
            $.ajax({
              url: "/wp-json/api/v1/recaptcha",
              xhrFields: {
                withCredentials: true
              },
              data : recaptch_data,
              method: 'POST',
              success: function(result) {
                console.log('recaptcha3');
                console.log(result.success);
                
                // Call internal MC API
                callMCAPI();

              },
              error: function(jqXHR, textStatus, errorThrown){
                console.log('Error, failed reCaptcha');
                $('.error-msg').text('Error, failed reCaptcha');
                $('.error').show();
              }
            });
          }).catch(function (error) {
            console.error("Error fetching IP:", error);
          });

          e.preventDefault();
        });
      },
      finalize: function() {
      }
    },
    // Confirm page
    'page_template_page_confirm': {
      init: function() {

        var u = new URL(window.location.href);
        var redirect = u.searchParams.get('redirect');

        var companyTooltipContainer = "#tooltipContainer";
        var companyTooltipText = "#company-info-box";
        var input_selector = "input[type=password]";
        var comapny_selector = "#company-name";

        $(document).on("mouseover", companyTooltipContainer, function () {
          $(companyTooltipText).css("display", "block");
        });
        $(document).on("mouseout", companyTooltipContainer, function () {
          $(companyTooltipText).css("display", "none");
        });
        $(document).on("change", input_selector, function () {
          validateField($(this));
        });
        $(document).on("change", comapny_selector, function () {
          validateField($(this));
        });

        $( "form.confirm-form" ).submit(function( e ) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line

          var isValid = validateField($(this).find('input[type="password"]'));
          var isCompanyValid = validateField($(this).find('#company-name'));

          if(!isCompanyValid){
            $('#company-error').show();
          }

          if(!isValid || !isCompanyValid) {
            return;
          }

          var $form = $(this);
          var api_url = $form.data('api-url');
          var token = $form.data('token');
          var success_url = redirect || $form.data('success-url');
          var method = 'POST';
          // Form values
          var email = $form.find('input[name=email]').val();
          var industry = parseInt($form.find('select[name=industry]').val());
          var password = $form.find('input[name=password]').val();
          var first_name = $form.find('input[name=first_name]').val();
          var last_name = $form.find('input[name=last_name]').val();
          var heard_about = $form.find('input[name=heard_about]').val();
          var company_name = $form.find('input[name=company_name]').val();
          var meta_data = $form.find('input[name=meta]').val();
          // onboarding params
          var params = $form.data('params') || {};

          industry = 0 === industry ? '' : industry;

          var data = {
            token: token,
            email: email,
            industry: industry,
            password: password,
            first_name: first_name,
            last_name: last_name,
            heard_about: heard_about,
            company_name: company_name,
            meta: meta_data
          };

          if(params) {
            $.extend(data, params);
          }

          // delete data.company_name;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : data,
            method: method,
            success: function(result) {
              var user = {};
              try { user = JSON.parse(result); } catch(err) {/* do nothing*/ }
              var id = user.id;
              var options = {
                email: email,
                industry: industry,
                createdAt: Math.round(new Date().getTime() / 1000),
                firstName: first_name,
                lastName: last_name,
                // name: first_name + ' ' + last_name,
                heardAbout: heard_about,
                companyName:company_name
              };
              var str = window.location.href.split('?')[1] || '';
              (str.split('&') || []).forEach(function(kv) {
                var key = decodeURIComponent(kv.split('=')[0]);
                var value = decodeURIComponent(kv.split('=')[1]);
                // only set option if UTM parameter:
                if (value && /^utm_/i.test(key)) {
                  options[key] = value;
                }
              });

              var props = prefixKeys('sign_up_', params);
              $.extend(options, props);

              var alias = $.Deferred();
              window.analytics.alias(id, alias.resolve);
              setTimeout(function() { alias.resolve(); }, 250);
              alias.promise().then(function() {
                var identify = $.Deferred();
                window.analytics.identify(id, options, identify.resolve);
                setTimeout(function() { identify.resolve(); }, 250);
                return identify.promise();
              }).then(function(){
                var track = $.Deferred();
                window.analytics.track('Account created', track.resolve);
                setTimeout(function() { track.resolve(); }, 250);
                return track.promise();
              }).then(function() {
                window.location.href = success_url;
              });
            },
            error: function(jqXHR, textStatus, errorThrown){
              var jsonResponse = JSON.parse(jqXHR.responseText);
              var errorMessage = jsonResponse.message;
              $('.error-msg').text(errorMessage);
              $('.error').show();
              $('.password-requirements-info-box').css("top", "25%");
            }
          });
          event.preventDefault();
        });

        $("select").selectize({
          create: false
        });

      },
      finalize: function() {
      }
    },
    // Forgot Password page
    'page_template_page_forgotpassword': {
      init: function() {

        var u = new URL(window.location.href);
        var redirect = u.searchParams.get('redirect');

        $( "form.forgot" ).submit( function( event ) {
          // get vars
          var env = $(this).data('env');
          var api_url = $(this).data('api-url');
          var method = 'POST';
          var email = $(this).find('input[name=email]').val();
          var data = {
            email: email,
          };

          if(redirect) {
            data.redirect = redirect;
          }

          $.ajax({
            contentType: 'application/json',
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data: JSON.stringify(data),
            method: method,
            success: function(result) {
              $('form.forgot').remove();
              $('.login-text').html('<p>Please check your email for a link to complete updating your password.</p>');
            },
            error: function(jqXHR, textStatus, errorThrown){
              console.log('Error');
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $('.error-msg').text(errorMessage);
                 $('.error').show();
                 break;
                default:
                  jsonResponse = JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $('.error-msg').text(errorMessage);
                    $('.error').show();
                  }
              }
            }
          });
          event.preventDefault();
        });

        $( "form.forgot-step2" ).submit( function( event ) {
          // get vars
          var env = $(this).data('env');
          var api_url = $(this).data('api-url');
          var success_url = redirect || $(this).data('success-url');
          var method = 'POST';
          var email = $(this).find('input[name=email]').val();
          var hash = $(this).find('input[name=hash]').val();
          var password = $(this).find('input[name=password]').val();
          $.ajax({
            contentType: 'application/json',
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : JSON.stringify({
              email: email,
              hash: hash,
              password: password,
            }),
            method: method,
            success: function(result) {
              $('form.forgot-step2').remove();
              $('h1').html("Password updated successfully");
              $('.login-text').html("<p>Redirecting you to your account in 3 seconds.</p>");
              setTimeout(function() {
                window.location.href = success_url;
              }, 3000);
            },
            error: function(jqXHR, textStatus, errorThrown){
              console.log('Error');
              switch (jqXHR.status) {
                default:
                  jsonResponse = JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $('.error-msg').text(errorMessage);
                    $('.error').show();
                  }
              }
            }
          });
          event.preventDefault();
        });

      },
      finalize: function() {
      }
    },
    // Invitations page
    'page_template_page_invitations': {
      init: function() {

        var input_selector = 'input[type=email], input[type=text], input[type=password]';
        $(document).on('change', input_selector, function() {
          validateField($(this));
        });

        $( "form.signup-form" ).submit( function( e ) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);
          var invalidFields = $form.find(input_selector).filter(function(index, input) {
            return !validateField($(this));
          });

          if(invalidFields.length) {
            return;
          }

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);
          $form.find('#account-login').remove();
          $('.error-msg').html("");
          $('.error').hide();

          // get vars
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var first_name = $form.find('input[name=first_name]').val();
          var last_name = $form.find('input[name=last_name]').val();
          var role = $form.find('input[name=role').val();
          var password = $form.find('input[name=password]').val();
          var successUrl = $form.data('success-url');

          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;
          var params = $form.data('params') || {};
          const now = new Date();
          const UTCtime = now.toISOString();
          getPublicIP().then(function (ip) {
            var data = {
              email: email,
              first_name: first_name,
              last_name: last_name,
              role: role,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term,
              privacy: UTCtime,
              terms:UTCtime,
              ip:ip ? ip : '',
            };
  
            if(params) {
              $.extend(data, params);
            }
  
            data.password = password;
  
            $.ajax({
              url: api_url,
              xhrFields: {
                withCredentials: true
              },
              data : data,
              method: method,
              success: function(result) {
                $button.attr('disabled', false);
  
                var props = prefixKeys('invitation_', params);
                window.analytics.track('Invitation email sent', props);
  
                window.location.href = successUrl;
              },
              error: function(jqXHR, textStatus, errorThrown){
                $button.attr('disabled', false);
  
                // $('.error-msg').html("There was an error accepting invitation, contact <a href='mailto:hi@mailcharts.com'>hi@mailcharts.com</a> if you need assistance.");
                // $('.error').show();
                var jsonResponse = JSON.parse(jqXHR.responseText);
                var errorMessage = jsonResponse.message;
                $('.error-msg').text(errorMessage);
                $('.error').show();
                $('.password-requirements-info-box').css("top", "41%");
              }
            });
          }) .catch(function (error) {
            console.error("Error fetching IP:", error);
          });
        });
      }
    },
    // Our data
    'page_template_page_our_data': {
      init: function() {

        $( "form.cta-signup-form" ).submit( function(e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false); // jshint ignore:line
          var $form = $(this);

          var $button = $form.find('button[type="submit"]');
          if($button.is(':disabled')) {
            return;
          }
          $button.attr('disabled', true);

          var $wrap = $form.closest('.cta-signup');
          var $error = $form.find('.error');
          var api_url = $form.data('api-url');
          var method = 'POST';
          var email = $form.find('input[name=email]').val();
          var utm_source = ((document.cookie.match(/utm_source=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_medium = ((document.cookie.match(/utm_medium=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_campaign = ((document.cookie.match(/utm_campaign=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var utm_term = ((document.cookie.match(/utm_term=(.+?);/) || []).shift() || '')
            .split('=').pop().replace(/ga|;/gi, '') || null;
          var _ga = linkerParam;

          $.ajax({
            url: api_url,
            xhrFields: {
              withCredentials: true
            },
            data : {
              email: email,
              _ga: _ga,
              utm_source: utm_source,
              utm_medium: utm_medium,
              utm_campaign: utm_campaign,
              utm_term: utm_term
            },
            method: method,
            success: function(result) {
              $button.attr('disabled', false);
              window.analytics.track('Confirmation email sent', {
                url: window.location.href
              });

              $wrap.find('.step-1').hide();
              $wrap.find('.step-2').html(function(index, html){
                return html.replace('[email]', email);
              });
              $wrap.find('.step-2').show();
            },
            error: function(jqXHR, textStatus, errorThrown){
              $button.attr('disabled', false);
              switch (jqXHR.status) {
                case 500:
                 var errorMessage = 'Email not valid';
                 $error.text(errorMessage);
                 $error.show();
                 break;
                case 409:
                  window.location.href = '/signin?account_exists=true&email='+email;
                  break;
                default:
                  jsonResponse = jqXHR.responseText && JSON.parse(jqXHR.responseText);
                  if(jsonResponse.message!==undefined){
                    errorMessage = jsonResponse.message;
                    $error.text(errorMessage);
                    $error.show();
                  }
              }
            }
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
